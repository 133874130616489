<template>
  <div class="pageContainer">
    <h2 class="w-100 text-center graph-title">Microphone Data Graph</h2>
    <div class="chartContainerStyle">
      <DataChartBars :chart-data="fftChartData" :options="fftChartOptions" :styles="chartStyle"/>
    </div>
  </div>
</template>

<script>
import DataChartBars from '../components/DataChartBars.js'
import { AdjustingInterval } from '../assets/js/AdjustingInterval.js'

export default {
  name: 'DataGraphMic',
  components: {
    DataChartBars,
  },
  data () {
    return {
      chartStyle: {
        height: '100%',
        width: '95%'
      },

      fftChartOptions: {
        events: [],
        title: {
          display: true,
          text: "Sound FFT",
          fontColor: '#2c3e50',
          fontSize: "14"
        },        
        legend: {
          display: true,
          labels: {
            boxWidth: 0,
            boxHeight: 0,
          },
        },
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              min: 0,
              max: 100
            }
          }],
          xAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Frequenzbereich in Hz',
              fontColor: '#2c3e50'
            },
          }]
        },
        responsive: true,
        maintainAspectRatio: false,
      },

      sMicro: {
        cFftUuid: '00002121-702b-69b5-b243-d6094a2b0e24',
        cFftConfUuid: '00002122-702b-69b5-b243-d6094a2b0e24',
      },

      fftChartData: null,

      curData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      //prevData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      prevData: 0,

      timer: null      
    }
  },

  async mounted() {
    if(this.$store.state.sMotionAvailable) {
      console.log("Motion is available startig norifications")
      await this.$store.state.motHandles.acc.startNotifications()

      this.timer = new AdjustingInterval(this.loadFakeImuData, 250, this.onError)
      this.timer.start()

      //this.prevData = this.$store.state.motDataAcc.z.reverse().slice(0, 16)
    }
  },

  methods: {
    loadFakeImuData() {
      if(!this.$store.state.bleConnected) return

      //let zData = this.$store.state.motDataAcc.z.slice(0, 16)
      let zData = this.$store.state.motDataAcc.z.slice(-8)
      let labels = []
      let fakeData = []

      for(let i = 1; i < 16; i++) {
        labels.push(i * 500) // 8000Hz / 16 bins
      }

      for(let i = 0; i < 8; i++) {
        //fakeData[i] = (fakeData[i] - this.prevData[i]) * 10.0
        fakeData[i] = Math.abs((zData[i] - this.prevData[i])) * 100.0
      }

      for(let i = 8; i < 15; i++) {
        fakeData[i] = fakeData[14 - i]
      }

      this.prevData = zData.slice()
      //this.prevData = this.$store.state.motDataAcc.z.reverse().slice(0, 16)

      console.log(Math.max(...fakeData))

      this.fftChartData = {
        labels: labels,
        datasets: [{
          label: 'Magnitude',
          lineTension: 0,
          borderWidth: 2,
          borderColor: '#2c3e50',
          backgroundColor: ["red", "red", "orange", "orange", "yellow", "yellow", "green", "green", "teal", "teal", "blue", "blue", "purple", "purple", "pink", "pink"],
          data: fakeData
        }]
      }
    },

    onError() {
      console.warn('The drift exceeded the interval!');
    },

    async enableSensor() {
      const buf = new ArrayBuffer(1)
      let config = new Uint8Array(buf)
      config[0] = 0b00000001 // Enable - Default period cant be changed

      await this.$store.state.micHandles.micFftConf.writeValue(config)

      this.$store.state.micHandles.micFft.addEventListener('characteristicvaluechanged', this.microEventHandler)
      await this.$store.state.micHandles.micFft.startNotifications()

      this.timer = setInterval(this.loadData, 60)
    },

    mockData() {
      let labels = []
      let fftData = [60, 55, 50, 45, 40, 40, 40, 40, 35, 30, 25, 20, 15, 10, 5, 3]

      for(let i = 1; i < 17; i++) {
        labels.push(i * 500)
      }

      for(let i = 0; i < 16; i++) {
        fftData[i] += this.getRandominRange(-15, 16)
      }

      this.fftChartData = {
        labels: labels,
        datasets: [{
          label: 'Magnitude',
          lineTension: 0,
          borderWidth: 2,
          borderColor: '#2c3e50',
          backgroundColor: ["red", "red", "orange", "orange", "yellow", "yellow", "green", "green", "teal", "teal", "blue", "blue", "purple", "purple", "pink", "pink"],
          data: fftData
        }]
      }
    },

    getRandominRange(min, max) {
      return Math.random() * (max - min) + min
    },

    loadData() {
      let labels = []
      let fftData = this.$store.state.micData.fftBins
      //let fftData = this.$store.getters.getLastFftBins // ??? values dont get modified by calculations below ???
      //let smooth = 0.75

      //const weighting = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      //const weighting = [-17, -7, -6, -6, -4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      //const weighting = [-70, -15, -10, -5, -4, -3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

      // console.log("RAW")
      console.log(fftData)

      for(let i = 1; i <= 16; i++) {
        labels.push(i * 500) // 8000Hz / 16 bins

        // if(fftData[i] > 0) {
        //   fftData[i] = 10 * Math.log10(fftData[i])
        // }

        //fftData[i] += weighting[i]
        //if(fftData[i] < 2) fftData[i] = 0

        //fftData[i] = smooth * fftData[i] + ((1 - smooth) * this.prevFftData[i])
      }

      //this.prevFftData = fftData

      // console.log("FINAL")
      // console.log(fftData)

      this.fftChartData = {
        labels: labels,
        datasets: [{
          label: 'Magnitude',
          lineTension: 0,
          borderWidth: 2,
          borderColor: '#2c3e50',
          backgroundColor: ["red", "red", "orange", "orange", "yellow", "yellow", "green", "green", "teal", "teal", "blue", "blue", "purple", "purple", "pink", "pink"],
          data: fftData
        }]
      }     
    },

    microEventHandler(event) {
      const targetUuid = event.target.uuid
      let rawData = event.target.value
      let data

      if(targetUuid === this.sMicro.cFftUuid) {
        data = this.parseFftData(rawData)
        //console.log(data.bins)
        this.$store.commit('updateMicDataFft', data.bins)
      } else {
        console.log('microEventHandler: Unknown target uuid ' + targetUuid)
        console.log(rawData)
      }     
    },

    parseFftData(rawData) {
      let timestamp = (rawData.getUint8(0) | (rawData.getUint8(1) << 8) 
        | (rawData.getUint8(2) << 16) | (rawData.getUint8(3) << 24))

      let uInt8View = new Uint8Array(rawData.buffer)
      let fftBins = uInt8View.slice(4)

      return {ts: timestamp, bins: fftBins}
    },

    async disableSensor() {
      const buf = new ArrayBuffer(1)
      let config = new Uint8Array(buf)
      config[0] = 0b00000000 // Disable

      clearInterval(this.timer)

      await this.$store.state.micHandles.micFftConf.writeValue(config)

      this.$store.state.micHandles.micFft.removeEventListener('characteristicvaluechanged', this.microEventHandler)
      await this.$store.state.micHandles.micFft.stopNotifications()
    },

    cancelUpdates() {
      this.timer.stop()
    }
    
  }, // methods: 

  async beforeDestroy() {
    if(this.$store.state.bleConnected) {
      this.cancelUpdates()
      await this.$store.state.motHandles.acc.stopNotifications()
    }
  }
}
</script>

<style scoped>
.pageContainer {
  width: 100%;
  height: 100%;
  font-size: 0px;
}
.graph-title {
  font-size: 18px;
}
.chartContainerStyle {
  display: inline-block;
  height:  50%;
  width: 95%;
  margin-top: 30%;
}
</style>